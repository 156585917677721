import './bootstrap';
import Alpine from 'alpinejs';
import 'preline';

import '@wotz/livewire-sortablejs';

if (!window.Alpine) {
    window.Alpine = Alpine;
    Alpine.start();
}
